import React from "react";
import moment from "moment";
import Img from 'gatsby-image';

export default function SexyMeme(props) {
    const { meme } = props;
    const {
        html,
        frontmatter: {
            title,
            date,
            yearThing,
            image
        }
    } = meme;

    const _date = moment.utc(date);
    const year = _date.year();
    const month = _date.format('MMMM');
    const day = _date.date();
    const dayOfWeek = _date.format('ddd');
    const simpleDate = _date.format("YYYY-MM-DD");

    const altText = `${simpleDate} :: ${title}`;
    // const imgSrc = `/static/img/${img}`;
    return (
        <div>
            <h2>{dayOfWeek} {month} {day}, in the {year}th year of our {yearThing}</h2>
            {/* <img alt={altText} src={imgSrc} /> */}
            <Img fluid={image.childImageSharp.fluid} alt={altText} />
            <h3>virgin boi says</h3>
            <div dangerouslySetInnerHTML={{__html: html}} />
        </div>
    )
};
