import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import SexyMeme from "../components/sexyMeme";

export default function SexyMemePage({ data }) {
    const { markdownRemark: meme } = data;
    return (
        <Layout title={meme.frontmatter.title}>
            <SexyMeme meme={meme} />
        </Layout>
    );
};

export const query = graphql`
    fragment Meme on MarkdownRemark {
        id
        frontmatter {
            title
            date
            yearThing
            image {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
        html
        fields {
            slug
        }
        excerpt
    }
    query($slug: String!) {
        markdownRemark(fields: {
            slug: { eq: $slug }
        }) {
            ...Meme
        }
    }
`;
